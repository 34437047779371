import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { USER_SETTINGS_STORAGE_KEY } from "#src/constants/common";
import { RootState } from "../../store";

export interface UserSettings {
  channelBriefsAdminView: boolean;
}

const initialState: UserSettings = {
  channelBriefsAdminView: false,
};

const userSettingsSlice = createSlice({
  name: "userSettings",
  initialState,
  reducers: {
    setUserSettings(state, action: PayloadAction<Partial<UserSettings>>) {
      state = { ...state, ...action.payload };
      localStorage.setItem(USER_SETTINGS_STORAGE_KEY, JSON.stringify(state));
      return state;
    },
  },
});

export const selectUserSettings = (state: RootState) => state.userSettings;
export default userSettingsSlice.reducer;
export const { setUserSettings } = userSettingsSlice.actions;
